export const CatalogDataFragment = `
    id
    name
    is_default
    is_global
    is_full
    catalog_products_count
    organization_id
    organization {
        id
        name
    }
`
