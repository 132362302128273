// @flow
import React from 'react'
import AsyncSelect from 'src/components/_generic/forms/async-select/async-select'
import { withQueryModifiers } from 'src/components/Search/withQueryModifiers'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import type { QueryModifierSetterType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierSetterType'
import { useGetVendorsList } from 'src/api/vendors/useGetVendorsList'
import SelectVendorDropdownItem from 'src/components/Vendors/SelectVendor/SelectVendorDropdown/select-vendor-dropdown-item'
import VendorNotFoundCreateView from 'src/components/Vendors/CreateVendor/VendorNotFoundCreateView'
import Vendor from 'src/api/vendors/_entities/Vendor'

type Props = {
	onChange: (any) => any,
	styles?: Object,
	filters?: Object,
	hideCreate?: boolean,
	modifiers?: QueryModifierType,
	setters?: QueryModifierSetterType,
}

const SelectVendorDropdown = ({
	onChange,
	styles = {},
	filters = {},
	hideCreate = false,
	modifiers,
	setters,
	...rest
}: Props) => {
	if (!!filters) {
		modifiers = {
			...modifiers,
			filters: { ...modifiers?.filters, ...filters },
		}
	}

	const {
		vendors,
		isLoading: loading,
		paginatorInfo,
		getVendors,
	} = useGetVendorsList(modifiers)

	const handleInputChange = (searchVal) => setters.setSearchTarget(searchVal)
	const onCreateNewVendor = (vendor) => {
		typeof onChange === 'function'
			? onChange(vendor)
			: new Error('Required: prop onChange not passed to Component')
	}

	const options = () => {
		return !!rest?.manualOptions?.length ? rest?.manualOptions : vendors
	}

	const onMenuScrollToBottom = () => {
		return paginatorInfo?.lastPage > paginatorInfo?.currentPage
			? setters.setPage(paginatorInfo?.currentPage + 1)
			: {}
	}

	const filterResultsBySearchTerm = async (inputValue) => {
		let fetched = await getVendors({
			...modifiers,
			page: 1,
			resultsPerPage: 25,
			searchTarget: inputValue,
		})
		let queryData =
			(await fetched?.data?.map((vendor) => Vendor(vendor))) ?? []

		let filtered = queryData.filter((vendor) =>
			vendor?.label.toLowerCase().includes(inputValue.toLowerCase())
		)

		return Promise.resolve(filtered)
	}

	return (
		<AsyncSelect
			options={options()?.filter((vendor) =>
				vendor?.name
					?.toLowerCase()
					.includes(modifiers?.searchTarget?.toLowerCase())
			)}
			loading={loading}
			searchTarget={modifiers?.searchTarget}
			onLoadMore={filterResultsBySearchTerm}
			components={{ Option: SelectVendorDropdownItem }}
			onInputChange={handleInputChange}
			onMenuScrollToBottom={onMenuScrollToBottom}
			noOptionsMessage={({ inputValue }) => {
				if (!hideCreate) {
					return (
						<VendorNotFoundCreateView
							vendorName={inputValue}
							shouldAddToOrg={true}
							onSubmit={onCreateNewVendor}
							organizationId={
								modifiers?.filters?.organizations?.[0]
							}
						/>
					)
				} else {
					return `Vendor ${inputValue} not found`
				}
			}}
			minMenuWidth={300}
			onChange={onChange}
			styles={styles}
			cacheOptions={false}
			{...rest}
		/>
	)
}

export default withQueryModifiers(SelectVendorDropdown, null, [], 1000)
