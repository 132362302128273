// @flow
import { Query, useQuery } from 'src/api/index'
import { useQueryClient } from 'react-query'
import { GetVendorsListQuery } from 'src/api/vendors/get-vendors-list-query'
import type { QueryModifierType } from 'src/flowtypes/Objects/QueryModifiers/QueryModifierType'
import Vendor from 'src/api/vendors/_entities/Vendor'

export const useGetVendorsList = (modifiers: QueryModifierType) => {
	const queryClient = useQueryClient()

	const { data: vendorData, isLoading } = useQuery({
		key: ['vendors', { modifiers }],
		request: GetVendorsListQuery,
		options: {
			enabled: !!modifiers,
			staleTime: 1000 * 60 * 10,
		},
	})

	const vendors = vendorData?.data?.map((vendor) => Vendor(vendor)) ?? []
	const paginatorInfo = vendorData?.paginatorInfo ?? { lastPage: 1 }

	const getVendors = async (modifiers: QueryModifierType) => {
		return await queryClient.fetchQuery(
			['vendors', { modifiers }],
			Query(GetVendorsListQuery, queryClient)
		)
	}

	return { vendors, isLoading, paginatorInfo, getVendors }
}
