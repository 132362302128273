import { gql } from 'graphql-request'

export const GetVendorsListQuery = {
	identifier: 'get_vendors_list',
	gql: gql`
		query vendors($modifiers: QueryModifierInput) {
			vendors(modifiers: $modifiers) {
				data {
					... on Vendor {
						id
						name
						image_path
						units_of_measure_count
						units_of_measure {
							id
							name
						}
						default_order_email
						is_vetted
						is_catalogable
						is_transcription_skippable
						organization_vendor {
							id
							order_email
							account_number
							connection_status
							can_place_order
						}
					}
				}
				paginatorInfo {
					currentPage
					lastPage
					perPage
					total
				}
			}
		}
	`,
	formatResults: (data) => {
		return data?.vendors
	},
}
